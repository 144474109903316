<template>
  <div id="page-login">
    <div id="login">
      <div id="err" v-if="err">{{ err }}</div>

      <!-- <img src="@/assets/images/logo.png" alt="" class="mx-auto" /> -->
      <div class="d-flex justify-content-around align-items-center overflow-hidden">
        <img
          src="@/assets/images/terradrone_g_center.png"
          alt=""
          class="mx-auto"
        />
        <img src="@/assets/images/kjs.png" alt="" class="mx-auto" />
      </div>
      <label class="text-muted">Username or Email</label>
      <input type="text" v-model="username" @keyup.enter="login" />
      <label class="text-muted">Password</label>
      <input type="password" v-model="password" @keyup.enter="login" />
      <button
        class="btn btn-primary btn-block font-weight-normal"
        @click="login"
        :disabled="isLoading"
      >
        <div
          v-if="isLoading"
          class="spinner-border mr-2 p-1"
          style="height: 1.1em; width: 1.1em; border-width: 2px"
          role="status"
        ></div>
        Login
      </button>
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  name: "login",
  data() {
    return {
      err: null,
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    async login() {
      if (this.username && this.password) {
        try {
          this.err = null;
          this.isLoading = true;
          const auth = await API.post("auth/local", {
            identifier: this.username,
            password: this.password,
          });
          this.isLoading = false;
          localStorage.setItem("user", JSON.stringify(auth.data.user));
          localStorage.setItem("token", auth.data.jwt);

          this.$router.push({ path: "/" });
        } catch (err) {
          this.isLoading = false;
          this.err = "Your login credentials are invalid";
          console.error(err);
        }
      }
    },
  },
  mounted() {
    document.title = "Login | magardTELE";
  },
};
</script>